import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './counter.css'
import NumberCounter from 'number-counter'
const Counter = () => {
    return (
        <div className='counter-wrapper text-center' >
            <Row>
                <Col lg={4} md={4} sm={1} >
                    <p className='number' >
                        <NumberCounter end={500} start={100} delay={1.5} postFix="+" />
                    </p>
                    <p className='numberTitle' >Customers</p>
                </Col>
                <Col lg={4} md={4} sm={1} >
                    <p className='number' >
                        <NumberCounter end={40} start={10} delay={3} />
                    </p>
                    <p className='numberTitle' >Years of Experience</p>
                </Col>
                <Col lg={4} md={4} sm={1} >
                    <p className='number' >
                        <NumberCounter end={10} start={1} delay={3} />
                    </p>
                    <p className='numberTitle' >Train Members</p>
                </Col>
            </Row>

        </div>
    )
}

export default Counter