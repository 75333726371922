import React, { useRef, useState } from 'react'
import { Alert, Button, Col, Container, Row } from 'react-bootstrap'
import Map from '../components/home/map/map'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser'
const Contact = () => {
    const form = useRef()
    const [show, setShow] = useState(false);
    const [showDanger, setShowDanger] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();
        

        emailjs.sendForm('service_4pu7w91', 'template_8ghx0bo', form.current, 's3gch1OJLGm739-Bq')
            .then((result) => {
                form.current.reset();
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 2000);
            }, (error) => {
                setShowDanger(true)
                setTimeout(() => {
                    setShowDanger(false)
                }, 2000);
            });
    };
    return (
        <div >
            <Container className='py-5' >

                <Row>

                    <Col lg={9} md={6} sm={1} >

                        <h3 className="Heading" >Contact Form</h3>
                       <hr className='horLine'  />
                        {
                            show &&
                            <Alert variant='success' onClose={() => setShow(false)} dismissible>
                                <b>Your message is send successfully!</b>
                            </Alert>
                        }
                        {
                            showDanger &&
                            <Alert variant='danger' onClose={() => setShowDanger(false)} dismissible>
                                <b>Try again Later!</b>
                            </Alert>
                        }
                        <Form ref={form} onSubmit={sendEmail}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" placeholder="Enter Name" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter email" />
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control type="text" name="subject" placeholder="Subject" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" name="message" rows={8} placeholder="Subject" />
                            </Form.Group>



                            <Button variant="success" type="submit" className='w-100' size="lg">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                    <Col lg={3} md={6} sm={1} className="mobileMargin">
                        <h3 className="Heading" >Branches</h3>
                       <hr className='horLine'  />
                        <Card
                            border='success'
                            className="mb-2"
                            style={{ width: '20rem' }}
                        >
                            <Card.Header
                                style={{
                                    backgroundColor: '#0E6129',
                                }}
                            >
                                <h5 className="Heading mt-1" style={{ color: 'white' }} >Lahore Branch Office</h5>
                            </Card.Header>
                            <Card.Body                            >
                                <Card.Text>
                                    Office:F-10, 1st Floor, Fazal Apartments, 5-Faiz Road,
                                    Old Muslim Town Lahore - Pakistan<br />
                                    <b>Tel:</b> +92-42-363 15 232<br />
                                    <b>Fax:</b> +92-42-363 15 320
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card
                            border='success'
                            className="mb-2"
                            style={{ width: '20rem' }}
                        >
                            <Card.Header
                                style={{
                                    backgroundColor: '#0E6129',
                                }}
                            >
                                <h5 className="Heading mt-1" style={{ color: 'white' }} >Rawalpindi Branch Office</h5>
                            </Card.Header>
                            <Card.Body                            >
                                <Card.Text>
                                    Suite# 5-6, 1st Floor, National Business

                                    Center Shamsabad, Murree Road,

                                    Rawalpindi-46300, Pakistan<br />
                                    <b>Tel:</b> +92-51-49-35-584<br />
                                    <b>Fax:</b> +92-51-49-35-585
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card
                            border='success'
                            className="mb-2"
                            style={{ width: '20rem' }}
                        >
                            <Card.Header
                                style={{
                                    backgroundColor: '#0E6129',
                                }}
                            >
                                <h5 className="Heading mt-1" style={{ color: 'white' }} >Karachi Branch Office</h5>
                            </Card.Header>
                            <Card.Body                            >
                                <Card.Text>
                                    Shop# B-82, Grace Shopping Center, Maskan Chorangi, Abul Hasan Isphani Road, Gulshan-e-Iqbal,

                                    Karachi-75300, Pakistan<br />
                                    <b>Cell:</b> +92-332-25-36-402

                                    <br />

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Map />
        </div>
    )
}

export default Contact