import React from 'react'

const Map = () => {
    return (
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.180355924784!2d74.32264291559953!3d31.519206054352384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904888b9e794f%3A0x97b9e265f003dabb!2sCustom%20Clearance%20International!5e0!3m2!1sen!2s!4v1673513836535!5m2!1sen!2s"
            width="100%" height="250"

            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    )
}

export default Map