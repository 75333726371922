import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import './companies.css'
import { useNavigate } from "react-router-dom";
import partner1 from '../../../assets/images/companies/1.png'
import partner2 from '../../../assets/images/companies/2.png'
import partner3 from '../../../assets/images/companies/3.png'
import partner4 from '../../../assets/images/companies/4.png'
import partner5 from '../../../assets/images/companies/5.png'
import partner6 from '../../../assets/images/companies/6.png'
import partner7 from '../../../assets/images/companies/7.png'
import partner8 from '../../../assets/images/companies/8.png'
import partner9 from '../../../assets/images/companies/9.png'
import partner10 from '../../../assets/images/companies/10.png'
import partner11 from '../../../assets/images/companies/11.png'
import partner12 from '../../../assets/images/companies/12.png'
import partner13 from '../../../assets/images/companies/new.png'





const Companies = ({ all }) => {
    const navigate = useNavigate()
    return (
        <div>
            <Row >
                <Col lg={4} md={4} sm={1} className={all ? "companyMarginContent":"centerContainer"} >

                    <div>
                        <img
                            src={partner1}
                            alt=""
                            className='companyLogo'
                        />
                        {
                            all ?
                                <div>
                                    <p className='companyName' >ThermoFisher SCIENTIFIC</p>
                                    <hr />
                                    <p className='companyProducts' >Tissue Processing & Processing, Cryotomy, Embedding, Histology Staining, Fixatives, Microtome, Immunohistochemistry, CISH.</p>
                                </div>
                                : null

                        }
                    </div>
                </Col>
                <Col lg={4} md={4} sm={1} className={all ? "companyMarginContent":"centerContainer"} >
                    <div>
                        <img
                            src={partner2}
                            alt=""
                            className='companyLogo'
                        />
                        {
                            all ?
                                <div>
                                    <p className='companyName'  >Eppendorf Himac Technologies</p>
                                    <hr />
                                    <p>Ultracentrifuges, Refrigerated Centrifuges, General Purpose Centrifuge Large Scale Continuous Flow Ultracentrifuge, Blood Cell Washing Centrifuge.</p>
                                </div>
                                :
                                null
                        }
                    </div>
                </Col>
                <Col lg={4} md={4} sm={1} className={all ? "companyMarginContent":"centerContainer"} >
                    <div>
                        <img
                            src={partner3}
                            alt=""
                            className='companyLogo'
                        />
                        {
                            all ?
                                <div>
                                    <p className='companyName'  >MILTENYI BIOTEC</p>
                                    <hr />
                                    <p>MACS Sample Preparation gentle MACS Dissociators MACS Cell Separation, auto MACS Pro Separator, MACS Flow Cytometry MACS Quant Analyzers, MACS Antibodies MACS molecular CliniMACS System, MACS Cell Culture, MACS Media Imaging Agents</p>
                                </div>
                                : null
                        }
                    </div>
                </Col>

            </Row>
            <Row >
                <Col lg={4} md={4} sm={1} className={all ? "companyMarginContent":"centerContainer"} >
                    <div>
                        <img
                            src={partner4}
                            alt=""
                            className='companyLogo'
                        />
                        {
                            all ?
                                <div>
                                    <p className='companyName'  >BIOGENEX
                                    </p>
                                    <hr />
                                    <p>Immunohistochemistry, ISH FISH Systems, Antibodies, Reagents and Special Stains.</p>
                                </div>
                                :
                                null
                        }
                    </div>
                </Col>
                <Col lg={4} md={4} sm={1} className={all ? "companyMarginContent":"centerContainer"} >
                    <div>
                        <img
                            src={partner5}
                            alt=""
                            className='companyLogo'
                        />
                        {
                            all ?
                                <div>
                                    <p className='companyName'  >MOLICULAR DEVICES</p>
                                    <hr />
                                    <p>Microplate Readers, Cellular Imaging Systems, Clone Screening, Axon Patch-Clamp, Assay Kits.</p>
                                </div>
                                :
                                null
                        }
                    </div>

                </Col>
                <Col lg={4} md={4} sm={1} className={all ? "companyMarginContent":"centerContainer"} >

                    <div>
                        <img
                            src={partner6}
                            alt=""
                            className='companyLogo'
                        />
                        {
                            all ?
                                <div>
                                    <p className='companyName' >ANGLENATONI LIFE SCIENCE ITALY</p>
                                    <hr />
                                    <p>Freezers, Refrigerators.Biological Safety Cabinet Morgue, Sterilization Systems, Laminar Flow Hoods, Philological, Climatic and Thermostatic Rooms</p>
                                </div>
                                :
                                null
                        }
                    </div>
                </Col>

            </Row>
            <Row style={{marginTop:'2rem'}} >
                <Col lg={4} md={4} sm={1} className={all ? "companyMarginContent":"centerContainer"} >
                    <div>
                        <img
                            src={partner7}
                            alt=""
                            className='companyLogo'
                        />
                        {
                            all ?
                                <div>
                                    <p className='companyName'>BWB TECHNOLOGIES</p>
                                    <hr />
                                    <p>Flame Photometer </p>
                                    <p style={{color:'#fff'}} >Flame PhotometerFlame PhotometerFlame PhotometerFlame PhotometerFlame Photometer </p>
                                </div>
                                : null
                        }
                    </div>
                </Col>
                <Col lg={4} md={4} sm={1} className={all ? "companyMarginContent":"centerContainer"} >
                    <div>
                        <img
                            src={partner8}
                            alt=""
                            className='companyLogo'
                        />
                        {
                            all ?
                                <div>
                                    <p className='companyName'  >EDINBURGH INSTRUMENTS</p>
                                    <hr />
                                    <p>Uv visible spectrophotometers, Flouroscence, Spectrophotometer, Raman Microscopes</p>
                                </div>
                                :
                                null
                        }
                    </div>
                </Col>
                <Col lg={4} md={4} sm={1} className={all ? "companyMarginContent":"centerContainer"} >
                    <div>
                        <img
                            src={partner9}
                            alt=""
                            className='companyLogo'
                        />
                        {
                            all ?
                                <div>
                                    <p className='companyName' >PRIORCLAVE</p>
                                    <hr />
                                    <p>Top-Loading Autoclave, Front-Loading Autoclaves, Benchtop Autoclaves, Rectangular Autoclaves, Double-Door/Pass-Through Autoclaves, Power Door Autoclaves, Horizontal Autoclaves, Large Capacity Autoclaves.</p>
                                </div>
                                : null
                        }

                    </div>
                </Col>

            </Row>
            {
                all ?
                    <div>
                        <Row >
                            <Col lg={4} md={4} sm={1} className="centerContainer" >
                                <div>
                                    <img
                                        src={partner10}
                                        alt=""
                                        className='companyLogo'
                                    />
                                    <p className='companyName' >EUROMEX MICROSCOPES
                                    </p>
                                    <hr />
                                    <p>Upright microscopes, Stereo microscopes, Dark field microscopes, Digital Solutions, Fluorescence microscopes, Inverted microscopes, Illumination Solutions, Phase contrast microscopes, Polarization microscopes.</p>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={1} className="centerContainer" >
                                <div>
                                    <img
                                        src={partner11}
                                        alt=""
                                        className='companyLogo'
                                    />
                                    <p className='companyName' >INSTRUMENTATION LABORATORY</p>
                                    <hr />
                                    <p>Hemostasis Lab Systems, Whole Blood Hemostasis Testing, IT Solutions, HemosIL" Reagents, Blood Gas Systems, Systems, Diagnostic Panel's.</p>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={1} className="centerContainer" >
                                <div>
                                    <img
                                        src={partner12}
                                        alt=""
                                        className='companyLogo'
                                    />
                                    <p className='companyName' >BERND KRAFT</p>
                                    <hr />
                                    <p>Customized Laboratory Chemicals and Reagents, AAS Standards & Reagents ICP Standards, Standards for ion Chromatography, Solvent Mixtures, Buffer Solutions, Laboratory Chemicals for Water and Waste Water analysis</p>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col lg={4} md={4} sm={1} className="centerContainer" >
                                <div>
                                    <img
                                        src={partner13}
                                        alt=""
                                        style={{
                                            height: '6rem',
                                            width: '13rem',
                                            marginBottom: "6rem",
                                            marginTop: "2rem"
                                        }}
                                    />
                                    <p className='companyName' >Dynamica Scientific UK</p>
                                    <hr />
                                    <p>Spectrophotometer, Microplate Instrumentation, DNA Analyzer, Centrifuge, pH Meter</p>
                                </div>
                            </Col>


                        </Row>

                    </div>
                    : <Button
                        className='customBtn float-end'
                        variant="outline-primary"
                        onClick={() => {
                            navigate('/principles')
                        }}

                    >
                        See All Partners
                    </Button>
            }

        </div>
    )
}

export default Companies