import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

const ServicesPage = () => {
    const packages = [
        {
            title: 'Standard Care',
            facilites: [
                'Preventive Maintance',
                'Priority Response'
            ]

        },
        {
            title: 'Extended Care',
            facilites: [
                'Preventive Maintance',
                'Priority Response',
                'Guaranteed Response Time'
            ]

        },
        {
            title: 'Total Care',
            facilites: [
                'Preventive Maintance',
                'Priority Response',
                'Guaranteed Response Time',
                'Original Spare Parts Included'
            ]

        },
    ]
    return (
        <Container className='pt-5'>

            <h2 className="Heading" >Services</h2>
           <hr className='horLine'  />
            <p className='lead' >
                When you purchase your instrument from Pakistan Scientific Traders, you choose a high-quality product that you rely upon or its accuracy and stability. Given the proper care, your instrument should provide years of reliable operation. Pakistan Scientific Traders have several service programs designed to meet your needs. From the recommended preventive maintenance programs to repair coverage and emergency service,Pakistan Scientific Traders will ensure you get the most out of your investment.<br />
                <br />
                To meet the growing demand for professional and qualified technical services, Pakistan Scientific Traders Technical Service Department has a team of highly qualified and skilled service professionals with a wide-ranging expertise in installation, configuration and commissioning. Our staff is fully trained and equipped to meet the service requirements of our clients.<br />
                <br />
                Pakistan Scientific Traders proposes annual contracts with the objective of reducing or even eliminating the need for remedial interventions which are frequently penalizing a laboratory. Excellent results under testing conditions have allowed us to establish a dynamic and efficient service structure. Our service engineers and application chemists are fully equipped to provide immediate on-location backup services to our customers. Similarly, the after sales services personnel of Pakistan Scientific Traders are fully trained to not only intervene for preventive or remedial maintenance on all laboratory equipment, but also for installation and training needs.<br />
            </p>

            <div className='text-center' >
                <h2 className="Heading pt-5" >The Right Package</h2>
               <hr className='horLine'  />
                <p className='lead' >
                    The services offered by Pakistan Scientific Traders are geared to your requirements. You can choose from a range of three Annual Maintenance Contracts, which build on each other in terms of scope and provision.

                </p>

                <Row className='my-5' >
                    {
                        packages.map((item, index) => {
                            return (

                                <Col lg={4} md={4} sm={1} className="mobileMargin">
                                    <Card
                                        bg={'success'}
                                        text='white'
                                    >
                                        <Card.Header
                                            style={{ height: '4.3rem', fontSize: "1.5rem" }}
                                            className="pt-3"
                                        >
                                            <b>{item.title}</b>
                                        </Card.Header>
                                        <ListGroup variant="flush">
                                            {
                                                item.facilites.map((item, _) => (
                                                    <ListGroup.Item>{item}</ListGroup.Item>
                                                ))
                                            }

                                        </ListGroup>
                                    </Card>
                                </Col>



                            )
                        })
                    }
                </Row>
            </div>
            <h2 className="Heading" >Benefits and Other Services</h2>
           <hr className='horLine'  />
            <p className='lead' >
                <ul>
                    <li>Certified test equipment</li>

                    <li>Easy-to-plan maintenance budgets</li>

                    <li>Ensure a long instrument working life</li>

                    <li>Have access to our team of highly qualified engineers</li>

                    <li>Keep your results on-spec</li>

                    <li>Rapid response for repairs</li>

                    <li>Installation & Commissioning</li>

                    <li>Application Support</li>

                    <li>Annual Maintenance Contracts</li>

                    <li>Customer Seminars & Training</li>

                    <li>System Configuration</li>

                    <li>Warranty Service</li>


                    <li>Repairs & Maintenance</li>
                </ul>
            </p>


        </Container>
    )
}

export default ServicesPage