import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './components/header/navbar';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from './pages/home';
import './App.css'
import Footer from './components/footer/footer';
import PrinciplesPage from './pages/principles';
import ServicesPage from './pages/services';
import AboutUs from './pages/about';
import Contact from './pages/contact';
import Bio from './pages/companyBio';

function App() {
  return (
    <Router>
      <NavbarComponent />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/principles" exact element={<PrinciplesPage />} />
        <Route path="/services" exact element={<ServicesPage />} />
        <Route path="/aboutus/history" exact element={<AboutUs />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/aboutus/bio" exact element={<Bio />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
