import React from 'react'
import { Container } from 'react-bootstrap'
import './about.css'
import { ChevronDoubleRight } from 'react-bootstrap-icons';

const Bio = () => {
    return (
        <Container className='py-5' >
            <h2 className='Heading'>Company Philosophy</h2>
            <hr className='horLine'  />
            <br /><br />
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: "1rem",

            }} >
                <ChevronDoubleRight color="#0E6129" size={50} />
                <h3 className='Heading' style={{ color: '#0E6129', paddingTop: '0.7rem', }} >Our mission</h3>
            </div>
            <hr className='horLine'  />
            <p className='lead' >

                Our organization is driven towards a single goal: customer satisfaction. We at Pakistan Scientific Traders aim to ensure that our market leadership extends far beyond hardware, into turfs like customer loyalty, product diversity, and the establishment of mutual trust between our customers, suppliers, and employees. Our mission is not the quantitative growth of the company, but to ensure that through our diversity, speed, and quality we are able to bring about an improvement in the quality of life of society at large, by partnering with our customers to create economic opportunity.
            </p>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: "1rem",

                }}
            >
                <ChevronDoubleRight color="#0E6129" size={50} />
                <h2 className='Heading' style={{ color: '#0E6129', paddingTop: '0.7rem', }} >Our Vision</h2>
            </div>

            <hr className='horLine'  />
            <p className='lead' >
                We aspire to become a leading global and national enterprise in the chemical, analysis, industry and the partner of choice for laboratory devices and solutions.
            </p>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: "1rem",

                }}
            >
                <ChevronDoubleRight color="#0E6129" size={50} />
                <h2 className='Heading' style={{ color: '#0E6129', paddingTop: '0.7rem', }} >Our Corporate Values</h2>
            </div>
            <hr className='horLine'  />
            <p className='lead' >
                We perform with integrity at all levels and continue to ensure corporate excellence through innovation and focused quality conscious efforts in all our ventures. We strive to play our role as a good corporate citizen, by saving the people and society for a better tomorrow.
            </p>

        </Container>
    )
}

export default Bio