import React from 'react'
import { Container } from 'react-bootstrap'
import './moto.css'


const Moto = () => {
    return (
        <Container className='moto-wrapper pb-4 pt-5' fluid>
            <center>
                <h2 className="Heading" style={{ color: "black" }} >Our Moto</h2>

                <p className='tagline lead' >
                    <em>"Satisfied customer is our best reference"</em>
                </p>
            </center>

        </Container>
    )
}

export default Moto