import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './footer.css'
import Logo from '../../assets/images/logos/pstLogo.png'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footer-wrapper py-5 w-100' >
            <Container>
                <Row>
                    <Col lg={4} md={4} sm={1} >
                        <img src={Logo} alt="" className='footerLogo' />

                        <p className='address' style={{ marginTop: "4rem" }} >
                            <span style={{ fontWeight: "Bold" }}>Email:</span>
                            <span style={{ textDecoration: 'underline' }} >info@pakistanscientific.com.pk</span>
                        </p>
                    </Col>
                    <Col lg={4} md={4} sm={1} className="mobileMargin" >
                        <div className='menu' >
                            <p className='text-style' >Site Map</p>
                            <hr className='horLine' />
                            <ul className='menu-list' >
                                <li>
                                    <Link className='footer-link' >Home</Link>
                                </li>
                                <li>
                                    <Link className='footer-link' >Principles</Link>
                                </li>
                                <li>
                                    <Link className='footer-link' >Services</Link>
                                </li>
                                <li>
                                    <Link className='footer-link' >About us</Link>
                                </li>
                                <li>
                                    <Link className='footer-link' >Contact us</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={1}  >
                        <div>
                            <p className='text-style' style={{ marginLeft: "0" }} >Offices</p>
                            <hr className='horLine' />
                            <p className="address" >
                                Lahore Head Office <br />
                                Office:F-10, 1st Floor, Fazal Apartments, 5-Faiz Road,<br /> Old Muslim Town
                                Lahore - Pakistan<br />
                                Tel: +92-42-363 15 232<br />
                                Fax: +92-42-363 15 320<br />
                            </p>
                            <hr className='horLine' />
                            <p className="address" >
                                Rawalpindi Branch Office <br />
                                Suite# 5-6, 1st Floor, National Business  <br />
                                Center Shamsabad, Murree Road,  <br />
                                Rawalpindi-46300, Pakistan <br />
                                Tel: +92 51 4935584 <br />
                                Fax: +92 51 4935585 <br />

                            </p>
                            <hr className='horLine' />
                            <p className="address" >
                                Shop# B-82, Grace Shopping Center, Maskan Chorangi,<br /> Abul Hasan Isphani Road, Gulshan-e-Iqbal, <br />Karachi-75300, Pakistan
                                <br />
                                Tel: +92 51 4935584 <br />
                                Fax: +92 51 4935585 <br />

                            </p>

                        </div>

                    </Col>
                </Row>
                <hr className='horLine' />
                <div>
                    <center>   
                         <p className='text-style pt-4' >All rights reserver by PAKISTAN SCIENTIFIC TRADERS LTD &copy; 2023</p>
                    </center>
                </div>
            </Container>
        </div>
    )
}

export default Footer